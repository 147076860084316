// export interface ContractData {
//   title: string
//   introduce?: string
//   paragraphs: ContractParapgraph[]
// }

// export interface ContractParapgraph {
//   title: string
//   paragraphs: ContractParapgraph[]

// }

export interface Paragraph {
  title?: string
  // content?: string
  // // number: string
  // subpoints?: Paragraph[]
  items: ParagraphItem[]
}

export interface ParagraphItem {
  content: string
  list?: string[]
}

export interface ContentStructure {
  title: string
  introduce?: string
  paragraphs: Paragraph[]
}

export enum ContractEnum {
  CONTRACT = 'contract',
  STATEMENT = 'statement',
  PROJECT_DIIA = 'project-diia',
  ENG = 'eng',
}
